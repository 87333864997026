/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "./src/assets/css/bootstrap.min.css"
// import "./src/assets/css/style.css";
// import "./src/assets/css/animate.css";
// import "./src/assets/css/icofont.min.css";
// import "./src/assets/css/owl.theme.default.min.css";

// import "jquery/dist/jquery.min.js";
// import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";
